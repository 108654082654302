type FullName = {
  first: string;
  middle?: string;
  last: string;
};
namespace FullName {
  export const format = (name: FullName | undefined | null) =>
    !name
      ? ""
      : `${name.first} ${name.middle ? `${name.middle} ` : ""}${name.last}`;
}

export default FullName;
